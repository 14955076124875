/* src/App.css */
body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  scroll-behavior: smooth;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.project-title {
  text-shadow: -2px 2px 2px black;
}

