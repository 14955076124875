/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes backgroundGrayAnim {
  0% {
    background-color: rgb(115 115 115);
  }
  100% {
    background-color: rgb(92 92 92);
  }
}

.backgroundGrayAnim {
  animation: backgroundGrayAnim 2s
    linear(
      0 0%,
      0.22 2.1%,
      0.86 6.5%,
      1.11 8.6%,
      1.3 10.7%,
      1.35 11.8%,
      1.37 12.9%,
      1.37 13.7%,
      1.36 14.5%,
      1.32 16.2%,
      1.03 21.8%,
      0.94 24%,
      0.89 25.9%,
      0.88 26.85%,
      0.87 27.8%,
      0.87 29.25%,
      0.88 30.7%,
      0.91 32.4%,
      0.98 36.4%,
      1.01 38.3%,
      1.04 40.5%,
      1.05 42.7%,
      1.05 44.1%,
      1.04 45.7%,
      1 53.3%,
      0.99 55.4%,
      0.98 57.5%,
      0.99 60.7%,
      1 68.1%,
      1.01 72.2%,
      1 86.7%,
      1 100%
    )
    infinite alternate;
  transition: background-color 0.5s;
}

.limitToTwoLines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em; /* Adjust based on the line height */
}

.limitToTwoLines:hover {
  -webkit-line-clamp: 100;
  max-height: none;
}